<template>
  <div class="content activity-data">
    <div class="group-list">
      <fm-title title-text="届" :title-menus="[{'key': 'addGroup', label: '新增'}]" @clickTitleMenu="chooseGroup = null;openDialog=true"></fm-title>
      <div class="tb">
        <fm-table-new
          :columns="columnList"
          size="large"
          border="row"
          :data-list="groupList"
          :stripe="false"
          emptyPlaceholder="-">
          <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="[{key: 'detail', label: '班子情况'}, {key: 'edit', label: '修改'}, {key: 'del', label: '删除'}]" :row="row"></table-actions>
        </fm-table-new>
      </div>
    </div>
    <div class="worker-list">
      <fm-title :title-text="chooseGroupDetail ? chooseGroupDetail.name + ' 班子情况' : '请选择届'" :title-menus="chooseGroupDetail ? [{'key': 'addWorker', label: '新增'}] : []" @clickTitleMenu="chooseWorker = null;openDialogWorker=true"></fm-title>
      <div class="tb" v-if="chooseGroupDetail">
        <fm-table-new
          :columns="columnListWorker"
          size="large"
          border="row"
          :data-list="workerList"
          :stripe="false"
          emptyPlaceholder="-">
          <table-actions slot="actions" @table-action="tableAction2" slot-scope="{ row }" :data="tableActions" :row="row"></table-actions>
        </fm-table-new>
      </div>
    </div>
    <fm-form-dialog
      form-title="届"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      :old-data="chooseGroup"
      :mask-closable="false"
      label-alone
      label-align="left"
      form-width="800px"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
    <fm-form-dialog
      form-title="班子情况"
      :open-dialog.sync="openDialogWorker"
      :form-parms="formParms2"
      :old-data="chooseWorker"
      :mask-closable="false"
      label-alone
      label-align="left"
      form-width="800px"
      @formSubmit="formSubmit2"
      @handleClose="openDialogWorker = false">
    </fm-form-dialog>
  </div>
</template>

<script>
import TableActions from '@/components/base/TableActions'

import {
  localstorageHelper,
  dateOperating
} from '@/fmlib'

export default {
  components: {
    TableActions
  },
  props: {
    org: {
      type: Object
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tableActions: [{key: 'edit', label: '修改'}, {key: 'del', label: '删除'}],
      allGroupList: [],
      groupList: [],
      chooseGroup: null,
      chooseGroupDetail: null,
      openDialog: false,
      openDialogWorker: false,
      allWorkerList: [],
      workerList: [],
      chooseWorker: null,
      columnList: [{
        field: 'name',
        title: '名称'
      },
      {
        field: 'startTime',
        title: '开始时间',
        render: (h, rowData) => {
          return h('div', rowData && rowData.startTime ? rowData.startTime.slice(0, 10) : '-')
        }
      },
      {
        field: 'endTime',
        title: '结束时间',
        render: (h, rowData) => {
          return h('div', rowData && rowData.endTime ? rowData.endTime.slice(0, 10) : '-')
        }
      },
      {
        title: '操作',
        field: 'actions',
        slot: 'actions', width: 100, fixed: 'right', search: false, export: false, configurable: false}],
      columnListWorker: [{
        field: 'workerName',
        title: '姓名'
      },
      {
        field: 'roleName',
        title: '职务'
      },
      {
        title: '操作',
        field: 'actions',
        slot: 'actions', width: 100, fixed: 'right', search: false, export: false, configurable: false}]
    }
  },
  computed: {
    formParms: {
      get () {
        let data = [{
          type: 'input',
          label: '名称',
          key: 'name',
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '开始时间',
          key: 'startTime',
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '结束时间',
          key: 'endTime',
          check: {
            required: true
          }
        }]
        return data
      }
    },
    formParms2: {
      get () {
        let data = [{
          type: 'select',
          label: '党员',
          selectDatas: this.$store.getters.workerList,
          key: 'workerId',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '职务',
          selectDatas: this.$store.getters.roleList.filter(v => v.data.roleType === 'position'),
          key: 'roleId',
          check: {
            required: true
          }
        }]
        return data
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    tableAction ({data, action}) {
      this.chooseGroup = data
      if (action === 'edit') {
        this.openDialog = true
      } else if (action === 'del') {
        this.allGroupList = this.allGroupList.filter(v => v.id !== this.chooseGroup.id)
        localstorageHelper.updateData('orgGroups', this.allGroupList)
        this.loadData()
      } else if (action === 'detail') {
        this.chooseGroupDetail = data
        this.loadWorkers()
      }
    },
    tableAction2 ({data, action}) {
      this.chooseWorker = data
      if (action === 'edit') {
        this.openDialogWorker = true
      } else if (action === 'del') {
        this.allWorkerList = this.allWorkerList.filter(v => v.id !== this.chooseWorker.id)
        localstorageHelper.updateData('orgGroupWorkers', this.allWorkerList)
        this.loadWorkers()
      }
    },
    loadData () {
      this.allGroupList = localstorageHelper.getData('orgGroups') || []
      this.groupList = this.allGroupList.filter(v => v.orgId === this.org.id)
    },
    loadWorkers () {
      this.allWorkerList = localstorageHelper.getData('orgGroupWorkers') || []
      this.workerList = this.allWorkerList.filter(v => v.orgId === this.org.id && v.groupId === this.chooseGroupDetail.id)
    },
    clickTitleMenus (parm) {
      console.log(parm)
    },
    formSubmit (data) {
      let parm = Object.assign({}, data)
      parm.orgId = this.org.id
      parm.startTime = dateOperating.computeDay({days: 0, date: parm.startTime, format: 'yy-mm-dd hh:mm:ss'})
      parm.endTime = dateOperating.computeDay({days: 0, date: parm.endTime, format: 'yy-mm-dd hh:mm:ss'})
      if (!parm.id) {
        parm.id = new Date().getTime()
        this.allGroupList.push(parm)
      } else {
        this.allGroupList = this.allGroupList.map(v => v.id === parm.id ? parm : v)
      }
      localstorageHelper.updateData('orgGroups', this.allGroupList)
      this.openDialog = false
      this.loadData()
    },
    formSubmit2 (data) {
      let parm = Object.assign({}, data)
      parm.orgId = this.org.id
      parm.workerName = this.$store.getters.workerList.find(v => v.key === parm.workerId).data.name
      parm.roleName = this.$store.getters.roleList.find(v => v.key === parm.roleId).data.name
      parm.groupId = this.chooseGroupDetail.id
      if (!parm.id) {
        parm.id = new Date().getTime()
        this.allWorkerList.push(parm)
      } else {
        this.allWorkerList = this.allWorkerList.map(v => v.id === parm.id ? parm : v)
      }
      localstorageHelper.updateData('orgGroupWorkers', this.allWorkerList)
      this.openDialogWorker = false
      this.loadWorkers()
    }
  }
}
</script>

<style scoped lang="less">
.activity-data {
  display: flex;
  .group-list {
    flex: 1;
    padding-right: 20px;
  }
  .worker-list {
    flex: 1;
  }
  .tb {
    min-height: 20rem;
  }
}
</style>
