<template>
  <div class="content activity-data">
    <fm-form :inline="3" label-alone label-align="left" style="position: relative;">
      <fm-form-item label="名称">
        <fm-input-new placeholder="名称" v-model="orgData.name" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="编码">
        <fm-input-new placeholder="编码" v-model="orgData.code" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="等级">
        <fm-select absolute filterable :clearable="false" v-model="orgData.orgTypeId" :disabled="readOnly">
          <fm-option v-for="item in $store.getters.orgTypeList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="父级党组织">
        <fm-select absolute filterable :clearable="false" v-model="orgData.pId" :disabled="readOnly">
          <fm-option v-for="item in $store.getters.orgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="拥有职务"  style="width: 66.66%;">
        <fm-select absolute filterable :clearable="false" multiple v-model="orgData.roleIds" :disabled="readOnly">
          <fm-option v-for="item in positionRoleList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="组织地址">
        <fm-input-new placeholder="组织地址" v-model="otherInfo.zzdz" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="服务时间段">
        <fm-input-new placeholder="服务时间段" v-model="otherInfo.fwsjd" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="联系电话">
        <fm-input-new placeholder="联系电话" v-model="otherInfo.lxdh" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="组织简介" style="width: 100%;">
        <fm-input-new style="height: 10rem;" type="textarea" placeholder="组织简介" v-model="otherInfo.zzjj" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="党建品牌名称" style="width: 100%;">
        <fm-input-new style="height: 5rem;" type="textarea" placeholder="党建品牌名称" v-model="otherInfo.djppmc" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="品牌理念" style="width: 100%;">
        <fm-input-new style="height: 5rem;" type="textarea" placeholder="品牌理念" v-model="otherInfo.ppll" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="工作成效" style="width: 100%;">
        <fm-input-new style="height: 10rem;" type="textarea" placeholder="工作成效" v-model="otherInfo.gzcx" :disabled="readOnly"/>
      </fm-form-item>
      <logo-img :readOnly="readOnly" :orgData="otherInfo" @fileChange="logoFileChange"></logo-img>
      <cover-img style="width: 60%;" :readOnly="readOnly" :orgData="otherInfo" @fileChange="fileChange"></cover-img>
    </fm-form>
  </div>
</template>

<script>
import CoverImg from './coverImg'
import LogoImg from './logoImg'

export default {
  components: {
    CoverImg,
    LogoImg
  },
  props: {
    sourceData: {
      type: Object
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      orgData: {},
      otherInfo: {}
    }
  },
  computed: {
    positionRoleList () {
      return this.$store.getters.roleList.filter(v => v.data.roleType === 'position')
    }
  },
  watch: {
    sourceData: {
      handler () {
        let data = JSON.parse(JSON.stringify(this.sourceData))
        this.otherInfo = data && data.description ? JSON.parse(data.description) : {}
        this.orgData = data
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    fileChange (data) {
      this.otherInfo.coverFileId = data.id
    },
    logoFileChange (data) {
      this.otherInfo.logoFileId = data.id
    },
    getFormData () {
      let data = Object.assign({}, this.orgData)
      data.description = JSON.stringify(this.otherInfo)
      return data
    }
  }
}
</script>

<style scoped lang="less">
.activity-data {
  .fm-form-inline {
    padding: 0 0 0 16px !important;
  }
  .form-item-f {
    width: 100% !important;
  }
}
</style>
